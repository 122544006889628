<template>
  <div class="betslip" :class="{ wideScreenLayout: isWideScreenLayout }">
    <BetslipHeader :isMinimizeVisible="isMinimizeVisible" :showLastTickets="showLastTickets" />
    <keep-alive>
      <component
        :is="activeComponent.component"
        :bgImage="bgImage"
        :isBallDrawn="isBallDrawn"
        :productNameLabel="productNameLabel"/>
    </keep-alive>
    <BetslipFooter v-if="isBetslipActive" :isA="isA" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BetslipHeader from './BetslipHeader';
import BetslipFooter from './BetslipFooter';

export default {
  name: 'BetslipA',
  props: {
    bgImage: {
      type: String,
      default: null,
    },
    isA: {
      type: Boolean,
      default: true,
    },
    isBallDrawn: {
      type: Function,
      default: undefined,
    },
    isMinimizeVisible: {
      type: Boolean,
      default: false,
    },
    showLastTickets: {
      type: Boolean,
      default: true,
    },
    productNameLabel: {
      type: String,
      default: '',
    },
  },
  components: {
    BetslipHeader,
    BetslipFooter,
  },
  computed: {
    ...mapGetters('gamesBetslip', ['activeComponent', 'isBetslipActive', 'rules', 'roundNumber', 'totalPayment']),
    ...mapGetters(['isMobile']),
    isWideScreenLayout() {
      return !this.isMobile;
    },
  },
  methods: {
    ...mapActions('gamesBetslip', ['setTotalPaymentValue']),
  },
  mounted() {
    if (this.isA) {
      this.setTotalPaymentValue(this.roundNumber(this.rules.minBetAmount.value));
    }
  },
};
</script>

<style lang="scss" scoped>
.betslip {
  background-color: var(--background-2);
}

.betslip {
  &.wideScreenLayout {
    min-width: 300px;
    width: 300px;
  }
}
</style>
