import axios from 'axios';

const supportedLocales = ['en', 'sr-Latn', 'tr', 'hr', 'cs', 'ht', 'ro', 'az', 'pt', 'pt-br', 'bs'];
const deployment = window.location.hostname.includes('nsoft.app') ? process.env.VUE_APP_GAMES_STATIC_CLOUDFLARE : process.env.VUE_APP_GAMES_STATIC_NETLIFY;
const http = axios.create({
  baseURL: deployment,
  timeout: 5000,
});

export default {
  async getTranslations(locale) {
    const { data } = await http.get(`/i18n/${supportedLocales.includes(locale) ? locale : 'en'}.json`);
    return data;
  },
  async getHelp(productName, locale, companyUuid, theme) {
    // To handle cases like sr-Latn
    const lang = locale.toLowerCase().split('-')[0];
    const game = productName.toLowerCase();

    // Theme is dark by default, no parameter in that case
    const pageTheme = theme && theme !== 'dark' ? `.${theme}` : '';

    const params = [
      `${lang}${pageTheme}.${companyUuid}`,
      `${lang}${pageTheme}`,
      `en${pageTheme}`,
    ];

    const getHelpPage = async (index) => {
      if (index >= params.length) {
        // eslint-disable-next-line
        console.warn('Failed to fetch help');
        return null;
      }

      try {
        await http.get(`/help/${game}.${params[index]}.html`);
        return `${deployment}/help/${game}.${params[index]}.html`;
      } catch {
        return getHelpPage(index + 1);
      }
    };

    return getHelpPage(0);
  },
};
