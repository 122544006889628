import { format, parseISO } from 'date-fns';
import { isNil, isArray } from 'lodash';

// To divide arrays into rows in tricast for example
const mapReverseTricast = (outcome) => {
  const { value } = outcome;
  const racers = value.map((item, index) => `<span class="racer">${item}${index + 1 < value.length ? ',' : ''}</span>`);
  const bet = racers.join(' ');
  const dash = value.length > 2 && isArray(value[0]) ? '-' : '';
  return `${bet}${dash}`;
};

const mapForecastTricast = outcome => outcome.value.map(item => `<span class="racer">${item}</span>`).join(' - ');

// Create ball elements and highlight those that are drawn
const mapBalls = (balls, drawn, channel) => {
  const getColorStyle = number => (channel === 'Retail' ? '' : `style="color: var(--text-primary-${drawn?.includes(number) ? '1' : '3'});"`);

  const mappedBalls = balls.map((number, index) => {
    const ballElement = `<span ${getColorStyle(number)}>${number}</span>`;
    const lineBreak = (index + 1) % 10 === 0 ? '<br>' : '';
    return `${ballElement}${lineBreak}`;
  });

  return mappedBalls.join(' ');
};

// Create desired bet information for different games
// Default is for racers to avoid listing all of them
const getOutcome = (bet, product, channel) => {
  switch (product) {
    case 'LuckySix': {
      if (bet.value.includes(',')) {
        return mapBalls(bet.value.split(','), bet.eventValue.split(','), channel);
      }
      switch (bet.type) {
        case 7:
        case 11:
          return bet.value === '0' ? 'general_even' : 'general_odd';
        case 6:
        case 8:
          return bet.value === '0' ? 'general_under' : 'general_over';
        default:
          return bet.value;
      }
    }
    case 'LuckyX':
    case 'Keno':
    case 'VirtualPenaltyShootout':
    case 'NextSix': {
      const { outcome } = bet;
      if (outcome.value && isArray(outcome.value)) {
        return mapBalls(bet.outcome.value, bet.outcome.drawn, channel);
      }
      return `<span>${outcome.title}</span>`;
    }
    default: {
      const { outcome } = bet;
      let result;
      switch (bet.bet.id) {
        case 13:
          result = mapReverseTricast(outcome);
          break;
        case 10:
        case 11:
        case 12:
          result = mapForecastTricast(outcome);
          break;
        case 3:
        case 4:
        case 8:
        case 9:
          result = outcome.title;
          break;
        case 14:
          result = `<span>{racer} ${outcome.value[0]} - {racer} ${outcome.value[1]} </span>`;
          break;
        default:
          result = `<span>{racer} ${outcome.title}</span>`;
          break;
      }
      if (channel === 'Retail') return `<div>${result}</div>`;
      return `<div style="color: var(--text-primary-1);">${result}</div>`;
    }
  }
};

const formatDateTime = value => (value && value.length > 1 ? format(parseISO(value), 'dd.MM.yyyy HH:mm') : '-');

const statusCode = (status) => {
  switch (status) {
    case 'ACCEPTED': return '0';
    case 'WON': return '2';
    case 'PAIDOUT': return '5';
    case 'EXPIRED': return '13';
    default: return '-1';
  }
};

// For old tickets
const noSelectionsMap = (ticket, channel) => {
  const bets = [];

  // Ticket header info
  const ticketInfo = {
    id: ticket.id,
    ticketDateTimeUTC: format(ticket.ticketDateTimeUTC, 'dd/MM/yyyy HH:mm'),
    status: { value: ticket.status.value, id: ticket.status.id },
  };

  if (ticketInfo.status.value === 'PAYEDOUT') ticketInfo.status.value = 'paidout';

  // Payin info
  const payments = [
    { key: 'general_pay_in', value: ticket.payin, name: 'payment' },
    { key: 'general_payin_tax', value: ticket.payinTax, name: 'payinTax' },
    { key: 'general_payout', value: ticket.payout, name: 'payout' },
    { key: 'general_payout_tax', value: ticket.payoutTax, name: 'payoutTax' },
    { key: 'general_possible_payout', value: ticketInfo.status.id === '5' ? null : ticket.possiblePayout, name: 'possiblePayout' },
  ];

  // Possible payin info
  const maxPayments = ticketInfo.status.id === '0' ? [
    { key: 'general_possible_winning', value: ticket.maxPossibleWin },
    { key: 'ticket_possible_payout_tax', value: ticket.maxPossiblePayoutTax },
  ] : [];

  // Bets
  ticket.bets.forEach((bet) => {
    let betOdd = bet.odd && bet.odd !== 0 ? bet.odd.toFixed(2) : '-';
    if (bet.combinations && bet.combinations.length === 1) {
      betOdd = bet.combinations[0].odd.toFixed(2);
    }


    const betData = {
      id: bet.status.id,
      rowData: [
        { key: 'round', value: bet.eventId },
        { key: 'type', value: bet.typeValue ?? bet.bet.title },
        { key: 'bet', value: getOutcome(bet, ticket.product, channel) },
        { key: 'stake', value: bet.amount.toFixed(2) },
        { key: 'odds', value: betOdd },
        { key: 'winnings', value: bet.status.value === 'OPEN' ? '-' : bet.winnings?.toFixed(2) },
      ],
    };
    bets.push(betData);
  });

  return {
    ticketInfo,
    payments,
    maxPayments,
    bets,
  };
};

const prepareBetSelections = (ticket) => {
  const { selections, bets } = ticket;

  bets.forEach((bet) => {
    const betSelections = bet.selectionRefs.map(({ selectionIndex }) => {
      const selection = selections[selectionIndex] || {};
      return selection;
    });
    // eslint-disable-next-line no-param-reassign
    bet.selections = betSelections;
  });
  return bets;
};

// For new tickets
const selectionsMap = (ticketValue) => {
  const bets = [];
  const ticket = ticketValue.productTicket ?? ticketValue;

  // Ticket header info
  const ticketInfo = {
    id: ticket.codes[0].id,
    ticketDateTimeUTC: formatDateTime(ticket.createdAt),
    status: { id: statusCode(ticket.status), value: ticket.status },
  };

  // Pay in info
  const { totals } = ticket;

  // When sum of taxes isn't available, calculate manually
  let totalPayinTax = 0;
  let possiblePayoutTax = 0;
  let maximumPossiblePayoutTax = 0;
  // eslint-disable-next-line no-return-assign
  totals.taxes.forEach((tax) => {
    if (tax.type === 'PAYIN') totalPayinTax += tax.value;
  });
  totals.possiblePayout.taxes.forEach((tax) => {
    if (tax.type === 'PAYOUT') possiblePayoutTax += tax.value;
  });
  totals.maximumPossiblePayout.taxes.forEach((tax) => {
    if (tax.type === 'PAYOUT') maximumPossiblePayoutTax += tax.value;
  });

  // Extract payin info from ticket
  const payments = [
    { key: 'general_pay_in', value: totals.payment?.real.value, name: 'payment' },
    { key: 'general_payin_tax', value: totalPayinTax, name: 'payinTax' },
    { key: 'general_payout', value: totals.payout?.real.value, name: 'payout' },
    { key: 'general_payout_tax', value: ticketInfo.status.id !== '13' ? possiblePayoutTax : null, name: 'payoutTax' },
    { key: 'general_possible_payout', value: ticketInfo.status.id !== '5' && ticketInfo.status.id !== '13' ? totals.possiblePayout?.real.value || totals.payout?.real.value : null, name: 'possiblePayout' },
  ];

  // Extract possible winnings info from ticket
  const maxPayments = ticketInfo.status.id === '0' ? [
    { key: 'general_possible_winning', value: totals.winnings?.maxPossible },
    { key: 'ticket_possible_payout_tax', value: maximumPossiblePayoutTax },
  ] : [];

  // Bets
  const betSelections = prepareBetSelections(ticket);

  // Mapping bet selections to bets
  betSelections.forEach((bet) => {
    const { selections } = bet;

    selections.forEach((selection) => {
      const betData = {
        id: statusCode(bet.status),
        rowData: [
          { key: 'round', value: selection.eventDisplayId },
          { key: 'type', value: selection.marketName },
          { key: 'bet', value: Number(selection.outcomeId).toFixed(2) },
          { key: 'odds', value: selection.odds && selection.odds !== 0 ? selection.odds.toFixed(2) : '-' },
          { key: 'stake', value: bet.stake?.real.value.toFixed(2) },
          { key: 'winnings', value: bet.status === 'ACCEPTED' ? '-' : bet.winnings?.real.value.toFixed(2) },
          { key: 'result', value: selection.result ? Number(selection.result).toFixed(2) : '-' },
          {
            key: 'luckyMultiplier',
            value: selection.additionalDetails?.luckyMultiplier
              ? Number(selection.additionalDetails.luckyMultiplier).toFixed(2)
              : null,
          },
        ],
      };
      bets.push(betData);
    });
  });


  return {
    ticketInfo,
    payments,
    maxPayments,
    bets,
  };
};

const mapTicket = (ticket, ticketVersion = '1', channel = '') => {
  const filteredBets = new Set();

  // Differentiate old and new ticket model
  const {
    ticketInfo, payments, maxPayments, bets,
  } = ticketVersion === '1'
    ? noSelectionsMap(ticket, channel) : selectionsMap(ticket);

  // Add product name to ticket info
  const name = ticket.product ?? ticket.productName;
  ticketInfo.productName = name;

  const bonus = isNil(ticket.superBonus) ? null : ticket.superBonus;

  // Extract payin and max winnings info
  const payinInfo = payments.filter(obj => obj.value !== 0 && !isNil(obj.value));
  const maxWinningsInfo = maxPayments.filter(obj => obj.value !== 0 && !isNil(obj.value));

  // Properties that have no value in any bet should be removed from heading information
  // Except for winnings
  bets.forEach((bet) => {
    bet.rowData.forEach((item) => {
      const { key, value } = item;
      if ((key === 'winnings') || (!isNil(value) && value !== 0 && value !== '-')) {
        filteredBets.add(key);
      }
    });
  });

  // Filter out properties which are not valid
  bets.forEach((bet) => {
    bet.rowData.forEach((item) => {
      if (!filteredBets.has(item.key)) {
        // eslint-disable-next-line no-param-reassign
        bet.rowData = bet.rowData.filter(entry => entry.key !== item.key);
      }
    });
  });

  // Generate translations for each property
  const headers = [];
  bets[0].rowData.forEach((item) => {
    headers.push({ key: item.key, value: `general_${item.key.replace(/[A-Z]/g, match => `_${match.toLowerCase()}`)}` });
  });

  return {
    bets,
    headers,
    payinInfo,
    maxWinningsInfo,
    ticketInfo,
    bonus,
  };
};

export default { mapTicket };
