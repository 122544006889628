export default {
  clientApp: state => state.clientApp,
  config: state => state.config,
  isFullScreen: state => state.isFullScreen,
  streamUuid: state => state.streamUuid,
  numbersFormat: state => state.numbersFormat,
  isBalanceLowerThanPayment: (state, getters, rootState, rootGetters) => rootGetters['user/balance'] < rootGetters['betslip/payment'],
  isAudioOn: state => state.isAudioOn,
  isMobile: state => state.isMobile,
  isTablet: state => state.isTablet,
  isDesktop: state => state.isDesktop,
  isTerminal: state => state.isTerminal,
  manualBalanceUpdate: state => state.manualBalanceUpdate,
  manualBalanceIntervalValue: state => state.manualBalanceIntervalValue,
  oddType: state => state.oddType,
};
